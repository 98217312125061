export const translations = {
    en: {
      // Hero Section
      heroTitle: "Be informed, get perspective, stay updated",
      heroDescription: "Discover news that matters to you with Brieft's AI-powered platform that intelligently groups similar stories and streamlines your news consumption.",
      
      // Features Section
      featuresTitle: "Why choose Brieft?",
      featuresDescription: "Our platform is designed to improve how you consume news and understand different perspectives.",
      
      // Feature 1
      feature1Title: "Personalized Curation",
      feature1Description: "Discover news tailored to your interests and preferences, helping you focus on what matters most to you.",
      
      // Feature 2
      feature2Title: "Balanced Perspectives",
      feature2Description: "Our intelligent clustering brings together similar news stories, giving you a comprehensive view of developing topics.",
      
      // Feature 3
      feature3Title: "Stay Updated",
      feature3Description: "Follow topics and get notifications as stories develop, ensuring you never miss important updates.",
      
      // How It Works
      howItWorksTitle: "How Brieft Works",
      howItWorksDescription: "Our AI-driven technology groups similar news stories together, helping you get a complete understanding of each topic without the overwhelming noise.",
      feature1: "Intelligent clustering of similar stories",
      feature2: "Cross-language support for comprehensive coverage",
      feature3: "Personalized news discovery based on your interests",
      
      // B2B Section
      b2bTitle: "Brieft for Business",
      b2bTagline: "Affordable media intelligence that gives you actionable insights at a fraction of the cost.",
      b2bDescription: "Traditional media monitoring services are expensive and often out of reach for startups and SMEs. Brieft's B2B platform offers an affordable alternative with advanced AI-powered insights.",
      
      // B2B Features
      b2bFeature1Title: "Advanced Analytics",
      b2bFeature1Description: "Track media coverage with AI-powered analytics and trend detection",
      b2bFeature2Title: "Real-time Monitoring",
      b2bFeature2Description: "Enhanced with comprehensive coverage and real-time tracking",
      b2bFeature3Title: "Strategic Recommendations",
      b2bFeature3Description: "Proactive strategic guidance on when and how to respond",
      
      // CTA
      joinWaitlist: "Join B2B Waitlist",
      continueToForm: "Continue to Form",
      openWebApp: "Open Web App",
      privacyPolicy: "Privacy Policy",
      product: "Product",
      features: "Features",
      forBusiness: "For Business",
      company: "Company",
      contact: "Contact",

      // Form
      formTitle: "Join the B2B Waitlist",
      fullName: "Full Name",
      workEmail: "Work Email",
      companyName: "Company Name",
      role: "Role",
      selectRole: "Select your role",
      privacyTextBefore: "By signing up, you agree to our ",
      privacyPolicyText: "Privacy Policy",
      privacyTextAfter: " and to receive updates about our B2B platform."
    },
    da: {
      // Hero Section
      heroTitle: "Vær informeret, få perspektiv og bliv opdateret!",
      heroDescription: "Opddag nyheder, der betyder noget for dig, med Brieft's AI-drevne platform, der intelligent grupperer lignende historier og strømliner dit nyhedsforbrug.",
      
      // Features Section
      featuresTitle: "Hvorfor vælge Brieft?",
      featuresDescription: "Vores platform er designet til at forbedre måden, du forbruger nyheder og forstår forskellige emner på.",
      
      // Feature 1
      feature1Title: "Personlig Kurateret Indhold",
      feature1Description: "Opddag nyheder skræddersyet til dine interesser og præferencer, så du kan fokusere på det, der betyder mest for dig.",
      
      // Feature 2
      feature2Title: "Smart Kategorisering",
      feature2Description: "Vores intelligente klyngedannelse samler lignende nyheder og giver dig et omfattende overblik over udviklende emner.",
      
      // Feature 3
      feature3Title: "Forbliv Opdateret",
      feature3Description: "Følg emner og få notifikationer, når historier udvikler sig, så du aldrig går glip af vigtige opdateringer.",
      
      // How It Works
      howItWorksTitle: "Sådan fungerer Brieft",
      howItWorksDescription: "Vores AI-drevne teknologi grupperer lignende nyheder sammen, hvilket hjælper dig med at få en komplet forståelse af hvert emne uden den overvældende støj.",
      feature1: "Intelligent klyngedannelse af lignende historier",
      feature2: "Flersproget support for omfattende dækning",
      feature3: "Personlig nyhedsopdagelse baseret på dine interesser",
      
      // B2B Section
      b2bTitle: "Brieft for Business",
      b2bTagline: "Overkommelig medie-intelligens, der giver dig handlingsorienterede indsigter til en brøkdel af prisen.",
      b2bDescription: "Traditionelle medieovervågningstjenester er dyre og ofte utilgængelige for startups og SMV'er. Brieft's B2B-platform tilbyder et forstyrrende billigt alternativ med avancerede AI-drevne indsigter.",
      
      // B2B Features
      b2bFeature1Title: "Avanceret Analyse",
      b2bFeature1Description: "Spor mediedækning med AI-drevet analyse og tendensdetektering",
      b2bFeature2Title: "Realtidsovervågning",
      b2bFeature2Description: "Forbedret med omfattende dækning og realtidssporing",
      b2bFeature3Title: "Strategiske Anbefalinger",
      b2bFeature3Description: "Proaktiv strategisk vejledning om, hvornår og hvordan man reagerer",
      
      // CTA
      joinWaitlist: "Join B2B Waitlist",
      continueToForm: "Fortsæt til Formular",
      openWebApp: "Åbn Web App",
      privacyPolicy: "Privatlivspolitik",
      product: "Produkt",
      features: "Features",
      forBusiness: "For Virksomheder",
      company: "Virksomhed",
      contact: "Kontakt",
      
      // Form
      formTitle: "Tilmeld dig B2B Ventelisten",
      fullName: "Fulde Navn",
      workEmail: "Arbejds-Email",
      companyName: "Virksomhedsnavn",
      role: "Rolle",
      selectRole: "Vælg din rolle",
      privacyText: "Ved at tilmelde dig accepterer du vores Privatlivspolitik og at modtage opdateringer om vores B2B-platform.",
      privacyTextBefore: "Ved at tilmelde dig accepterer du vores ",
      privacyPolicyText: "Privatlivspolitik",
      privacyTextAfter: " og at modtage opdateringer om vores B2B-platform."  
    }
  };
  