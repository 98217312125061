import { useState, FormEvent, ChangeEvent } from 'react';
import { ArrowRight } from 'lucide-react';
import { useLanguage } from '../LanguageContext';

export default function WaitlistForm() {
  const { t } = useLanguage();
  
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    role: ''
  });
  
  const [formError, setFormError] = useState('');
  
  // Replace this with your actual Google Form URL
  const GOOGLE_FORM_URL = "https://docs.google.com/forms/d/e/1FAIpQLSfNB96PwUiFxEIl6C1sft_aC15UmdXRbpYYWCCgXxPcFeSPPg/viewform";

  // These should match the field IDs in your Google Form
  // You can find these by inspecting the form HTML or using the pre-fill tool
  const GOOGLE_FORM_NAME_FIELD = "entry.981550188"; // Replace with actual field ID
  const GOOGLE_FORM_EMAIL_FIELD = "entry.1343932802"; // Replace with actual field ID
  const GOOGLE_FORM_COMPANY_FIELD = "entry.654094555"; // Replace with actual field ID
  const GOOGLE_FORM_ROLE_FIELD = "entry.1500084718"; // Replace with actual field ID
  // const GOOGLE_FORM_OCCUPATION_FIELD = "entry.1661531876"; // Replace with actual field ID
  // const GOOGLE_FORM_PLANOFUSE_FIELD = "entry.1915861295"; // Replace with actual field ID
  // const GOOGLE_FORM_USAGEEXPECTATIONS_FIELD = "entry.1920750732"; // Replace with actual field ID
  // const GOOGLE_FORM_EXPERIENCE_FIELD = "entry.818200543"; // Replace with actual field ID

  const handleInputChange = (e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    // Validate form data
    if (!formData.name || !formData.email || !formData.company || !formData.role) {
      setFormError('Please fill in all required fields');
      return;
    }
    
    // Create URL with pre-filled form data
    const googleFormUrl = new URL(GOOGLE_FORM_URL);
    
    // Add form data as query parameters
    googleFormUrl.searchParams.append(GOOGLE_FORM_NAME_FIELD, formData.name);
    googleFormUrl.searchParams.append(GOOGLE_FORM_EMAIL_FIELD, formData.email);
    googleFormUrl.searchParams.append(GOOGLE_FORM_COMPANY_FIELD, formData.company);
    googleFormUrl.searchParams.append(GOOGLE_FORM_ROLE_FIELD, formData.role);
    
    // Redirect to the Google Form
    window.open(googleFormUrl.toString(), '_blank');
    
    // Reset the form
    setFormData({
      name: '',
      email: '',
      company: '',
      role: ''
    });
    
    // Show success message
    alert("Thanks for your interest! Please complete the additional questions in the form that just opened.");
  };
  
  return (
    <div className="bg-white p-8 rounded-xl shadow-lg border border-gray-200">
      <h3 className="text-2xl font-semibold mb-6">{t('formTitle')}</h3>
      
      {formError && (
        <div className="p-4 rounded-md mb-6 bg-red-50 text-red-800">
          <p>{formError}</p>
        </div>
      )}
      
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">{t('fullName')} *</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
            placeholder="Your name"
          />
        </div>
        
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">{t('workEmail')} *</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
            placeholder="you@company.com"
          />
        </div>
        
        <div>
          <label htmlFor="company" className="block text-sm font-medium text-gray-700 mb-1">{t('companyName')} *</label>
          <input
            type="text"
            id="company"
            name="company"
            value={formData.company}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
            placeholder="Your company"
          />
        </div>
        
        <div>
          <label htmlFor="role" className="block text-sm font-medium text-gray-700 mb-1">{t('role')} *</label>
          <select
            id="role"
            name="role"
            value={formData.role}
            onChange={handleInputChange}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-primary focus:border-primary"
          >
            <option value="">{t('selectRole')}</option>
            <option value="PR & Communications">PR & Communications</option>
            <option value="Marketing">Marketing</option>
            <option value="Executive / Management">Executive / Management</option>
            <option value="Founder">Founder</option>
            <option value="Other">Other</option>
          </select>
        </div>
        
        <div>
          <button
            type="submit"
            className="w-full px-6 py-3 bg-primary text-white rounded-md hover:bg-primary/90 transition-colors flex items-center justify-center"
          >
            {t('continueToForm')}
            <ArrowRight className="ml-2 h-4 w-4" />
          </button>
        </div>
        
        <p className="text-xs text-gray-500 mt-4">
          {t('privacyTextBefore')}
            <a href="/privacy" className="text-primary underline">
              {t('privacyPolicyText')}
            </a>
          {t('privacyTextAfter')}
        </p>
      </form>
    </div>
  );
}