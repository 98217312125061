import React from 'react';
import { useLanguage } from '../LanguageContext';

export const LanguageSelector: React.FC = () => {
  const { language, setLanguage } = useLanguage();

  return (
    <div className="flex items-center space-x-1">
      <button
        onClick={() => setLanguage('en')}
        className={`text-xs md:text-sm px-1.5 py-0.5 md:px-2 md:py-1 rounded ${
          language === 'en' 
            ? 'bg-primary text-white' 
            : 'text-gray-600 hover:bg-gray-100'
        }`}
        aria-label="Switch language to English"
      >
        EN
      </button>
      <button
        onClick={() => setLanguage('da')}
        className={`text-xs md:text-sm px-1.5 py-0.5 md:px-2 md:py-1 rounded ${
          language === 'da' 
            ? 'bg-primary text-white' 
            : 'text-gray-600 hover:bg-gray-100'
        }`}
        aria-label="Switch language to Danish"
      >
        DA
      </button>
    </div>
  );
};
