import logo from '../assets/brieft-logo.png';
import { useLanguage } from '../LanguageContext';
import { Link } from 'react-router-dom';

export default function Footer() {
    const { t } = useLanguage();

    return (
    <footer className="py-12 bg-white border-t border-gray-100">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="md:flex md:justify-between">
                <div className="mb-8 md:mb-0">
                <img src={logo} alt="Brieft Logo" className="w-32 mb-4" />
                <p className="text-gray-600 max-w-xs">
                    Balanced, multi-viewpoint news consumption that fuels AI-powered media intelligence.
                </p>
                </div>
                <div className="grid grid-cols-2 md:grid-cols-3 gap-8">
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                        {t('product')}
                    </h3>
                    <ul className="space-y-3">
                    <li>
                        <Link 
                            to="/#features"
                            className="text-base text-gray-600 hover:text-gray-900"
                        >
                            {t('features')}
                        </Link>
                    </li>
                    <li>
                        <Link 
                            to="/#b2b"
                            className="text-base text-gray-600 hover:text-gray-900"
                        >
                            {t('forBusiness')}
                        </Link>
                    </li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                        {t('company')}
                    </h3>
                    <ul className="space-y-3">
                    <li>
                        <Link 
                            to="/privacy"
                            className="text-base text-gray-600 hover:text-gray-900"
                        >
                            {t('privacyPolicy')}
                        </Link>
                    </li>
                    <li>
                        <a href="mailto:magic@spelllabs.ai" className="text-base text-gray-600 hover:text-gray-900">
                            {t('contact')}
                        </a>
                    </li>
                    </ul>
                </div>
                <div>
                    <h3 className="text-sm font-semibold text-gray-900 tracking-wider uppercase mb-4">
                        Download
                    </h3>
                    <ul className="space-y-3">
                    <li>
                        <a 
                        href="https://apps.apple.com/dk/app/brieft/id6740772302" 
                        className="text-base text-gray-600 hover:text-gray-900"
                        target="_blank"
                        rel="noreferrer"
                        >
                            iOS App
                        </a>
                    </li>
                    <li>
                        <a 
                        href="https://play.google.com/store/apps/details?id=com.brieft.app" 
                        className="text-base text-gray-600 hover:text-gray-900"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Android App
                        </a>
                    </li>
                    <li>
                        <a 
                        href="https://app.brieft.io/" 
                        className="text-base text-gray-600 hover:text-gray-900"
                        target="_blank"
                        rel="noreferrer"
                        >
                            Web App
                        </a>
                    </li>
                    </ul>
                </div>
                </div>
            </div>
            <div className="mt-8 pt-8 border-t border-gray-200">
                <p className="text-gray-500 text-sm">
                    © {new Date().getFullYear()} SpellLabs ApS. All rights reserved.
                </p>
            </div>
        </div>
    </footer>
  );
}