import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import logo from './assets/brieft-logo.png';
import Footer from './components/Footer';

export default function PrivacyPolicy() {
  return (
    <div className="min-h-screen flex flex-col bg-gray-50">
      {/* Header */}
      <header className="border-b bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-4">
          <div className="flex justify-between items-center">
            <Link to="/" className="flex items-center space-x-2">
              <ArrowLeft className="h-5 w-5 text-gray-600" />
              <span className="text-gray-600">Back to Home</span>
            </Link>
            <img src={logo} alt="Brieft Logo" className="w-32" />
          </div>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow py-12">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-white shadow rounded-lg p-8">
            <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
            
            <div className="space-y-8">
              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Introduction</h2>
                <p className="text-gray-600">
                  At Brieft, we take your privacy seriously. This Privacy Policy explains how we collect, 
                  use, disclose, and safeguard your information when you use our service. Please read 
                  this privacy policy carefully.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Information We Collect</h2>
                <div className="space-y-4">
                  <div>
                    <h3 className="font-medium text-gray-900">Personal Information</h3>
                    <p className="text-gray-600">
                      We may collect personal information that you voluntarily provide when using our service, including:
                    </p>
                    <ul className="list-disc ml-6 mt-2 text-gray-600">
                      <li>Email address</li>
                      <li>Name</li>
                      <li>Usage data and preferences</li>
                    </ul>
                  </div>
                  
                  <div>
                    <h3 className="font-medium text-gray-900">Usage Information</h3>
                    <p className="text-gray-600">
                      We automatically collect certain information when you use our service, including:
                    </p>
                    <ul className="list-disc ml-6 mt-2 text-gray-600">
                      <li>Device type and settings</li>
                      <li>Browser type and settings</li>
                      <li>Operating system</li>
                      <li>Usage patterns and preferences</li>
                    </ul>
                  </div>
                </div>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">How We Use Your Information</h2>
                <p className="text-gray-600">We use the collected information for various purposes:</p>
                <ul className="list-disc ml-6 mt-2 text-gray-600">
                  <li>To provide and maintain our service</li>
                  <li>To notify you about changes to our service</li>
                  <li>To provide customer support</li>
                  <li>To personalize your experience</li>
                  <li>To analyze and improve our service</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Data Storage and Security</h2>
                <p className="text-gray-600">
                  Your data is stored securely in the European Union, complying with GDPR requirements. 
                  We implement appropriate technical and organizational measures to protect your personal 
                  information against unauthorized or unlawful processing, accidental loss, destruction, 
                  or damage.
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Your Rights</h2>
                <p className="text-gray-600">Under GDPR, you have the following rights:</p>
                <ul className="list-disc ml-6 mt-2 text-gray-600">
                  <li>Right to access your personal data</li>
                  <li>Right to rectify inaccurate personal data</li>
                  <li>Right to request erasure of your personal data</li>
                  <li>Right to restrict processing of your personal data</li>
                  <li>Right to data portability</li>
                  <li>Right to object to processing of your personal data</li>
                </ul>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Contact Us</h2>
                <p className="text-gray-600">
                  If you have any questions about this Privacy Policy, please contact us at:
                  <br />
                  <a href="mailto:magic@spelllabs.ai" className="text-primary hover:underline">
                  magic@spelllabs.ai
                  </a>
                </p>
              </section>

              <section>
                <h2 className="text-xl font-semibold text-gray-900 mb-4">Changes to This Privacy Policy</h2>
                <p className="text-gray-600">
                  We may update our Privacy Policy from time to time. We will notify you of any changes by 
                  posting the new Privacy Policy on this page and updating the "Last updated" date.
                </p>
                <p className="text-gray-500 mt-4 text-sm">
                  Last updated: March 15, 2024
                </p>
              </section>
            </div>
          </div>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}