// import { useState } from 'react';
import { Newspaper, Scale, Bell, ArrowRight, BarChart2, LineChart, Zap, Check } from 'lucide-react';
import { useLanguage } from './LanguageContext';
import { LanguageSelector } from './components/LanguageSelector';
import logo from './assets/brieft-logo.png';
import appStoreBadge from './assets/app-store.svg';
import googlePlayBadge from './assets/google-play-badge.png';
import cluster from './assets/cluster.png';

import WaitlistForm from './components/WaitlistForm';
import Footer from './components/Footer';

export default function LandingPage() {
  const { t } = useLanguage();

  // const [faqOpen, setFaqOpen] = useState<Record<string, boolean>>({});
  // const toggleFaq = (id: string) => {
  //   setFaqOpen(prev => ({ ...prev, [id]: !prev[id] }));
  // };  
  
  return (
    <div className="min-h-screen flex flex-col bg-white">
      {/* Navigation */}
      <nav className="border-b border-gray-100 py-4 bg-white fixed w-full z-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center">
            <div>
              <img src={logo} alt="Brieft Logo" className="w-32 md:w-40" />
            </div>
            <div className="flex items-center space-x-6">
              <a href="#features" className="text-gray-600 hover:text-primary hidden md:block">{t('features')}</a>
              <a href="#b2b" className="text-gray-600 hover:text-primary hidden md:block">{t('forBusiness')}</a>
              <LanguageSelector />
              <a
                href="https://app.brieft.io/"
                target="_blank"
                rel="noreferrer"
                className="px-2 py-1 text-xs md:text-base md:px-4 md:py-2 rounded-md bg-primary text-white hover:bg-primary/90 transition-colors"
                >
                {t('openWebApp')}
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section */}
      <section className="pt-28 pb-16 md:pt-36 md:pb-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:flex md:items-center md:justify-between">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <h1 className="text-4xl md:text-5xl font-bold text-gray-900 leading-tight">
              {t('heroTitle')}
              </h1>
              <p className="text-xl text-gray-600 mt-6 mb-8 md:pr-10">
              {t('heroDescription')}
              </p>
              
              <div className="flex flex-wrap items-center gap-4 md:gap-6">
                <a
                  href="https://apps.apple.com/dk/app/brieft/id6740772302"
                  target="_blank"
                  rel="noreferrer"
                  className="h-[50px] rounded-md overflow-hidden"
                >
                  <img
                    src={appStoreBadge}
                    alt="Download on the App Store"
                    className="h-full"
                  />
                </a>

                <a
                  href="https://play.google.com/store/apps/details?id=com.brieft.app"
                  target="_blank"
                  rel="noreferrer"
                  className="h-[50px] rounded-md overflow-hidden"
                >
                  <img
                    src={googlePlayBadge}
                    alt="Get it on Google Play"
                    className="h-full"
                  />
                </a>

                <a
                  href="https://app.brieft.io/"
                  target="_blank"
                  rel="noreferrer"
                  className="h-[50px] rounded-md overflow-hidden"
                >
                  <div className="bg-black h-full flex items-center justify-left text-white p-3 gap-4">
                    <img
                      src="/icon.png"
                      alt="Brieft Icon"
                      className="h-9 w-9 rounded-md"
                    />
                    <div className="flex flex-col leading-tight items-start text-center">
                      <span className="text-[0.5rem] sm:text-xs leading-none uppercase">try the</span>
                      <span className="text-[1.2rem] font-semibold">WEB APP</span>
                    </div>
                  </div>
                </a>
              </div>
              
              <div className="mt-8 text-sm text-gray-500">
                <p>Join 100+ users who are experiencing news differently</p>
              </div>
            </div>

            <div className="md:w-1/2 flex justify-center">
              <div className="relative mx-auto border-gray-800 bg-gray-800 border-[14px] rounded-[2.5rem] shadow-xl">
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[124px] rounded-l-lg"></div>
                <div className="h-[46px] w-[3px] bg-gray-800 absolute -left-[17px] top-[178px] rounded-l-lg"></div>
                <div className="h-[64px] w-[3px] bg-gray-800 absolute -right-[17px] top-[142px] rounded-r-lg"></div>
                <div className="rounded-[2rem] overflow-hidden w-[270px] h-[588px] bg-white">
                  <video 
                    autoPlay 
                    loop 
                    muted 
                    playsInline
                    className="w-full h-full object-cover"
                  >
                    <source src="/app-demo.mp4" type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-gray-900">Why choose Brieft?</h2>
            <p className="text-xl text-gray-600 mt-4 max-w-2xl mx-auto">
            {t('featuresDescription')}
            </p>
          </div>
          
          <div className="grid md:grid-cols-3 gap-8 text-left">
            <div className="p-8 bg-white rounded-lg shadow-sm border border-gray-100 transition-all hover:shadow-md">
              <div className="p-3 bg-primary/10 rounded-full w-fit mb-6">
                <Newspaper className="h-7 w-7 text-primary" aria-hidden="true"/>
              </div>
              <h3 className="text-xl font-semibold mb-3">
              {t('feature1Title')}
              </h3>
              <p className="text-gray-600">
              {t('feature1Description')}
              </p>
            </div>

            <div className="p-8 bg-white rounded-lg shadow-sm border border-gray-100 transition-all hover:shadow-md">
              <div className="p-3 bg-primary/10 rounded-full w-fit mb-6">
                <Scale className="h-7 w-7 text-primary" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-semibold mb-3">
              {t('feature2Title')}
              </h3>
              <p className="text-gray-600">
              {t('feature3Description')}
              </p>
            </div>

            <div className="p-8 bg-white rounded-lg shadow-sm border border-gray-100 transition-all hover:shadow-md">
              <div className="p-3 bg-primary/10 rounded-full w-fit mb-6">
                <Bell className="h-7 w-7 text-primary" aria-hidden="true" />
              </div>
              <h3 className="text-xl font-semibold mb-3">
              {t('feature3Title')}
              </h3>
              <p className="text-gray-600">
              {t('feature2Description')}
              </p>
            </div>
          </div>
          
          <div className="mt-16 bg-white p-8 rounded-xl shadow border border-gray-100">
            <div className="md:flex md:items-center">
              <div className="md:w-2/3 mb-6 md:mb-0 md:pr-12">
                <h3 className="text-2xl font-bold text-gray-900 mb-4">{t('howItWorksTitle')}</h3>
                <p className="text-gray-600 mb-6">
                {t('howItWorksDescription')}
                </p>
                <ul className="space-y-3">
                  <li className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary/10 flex items-center justify-center mt-0.5">
                      <Check className="h-4 w-4 text-primary" />
                    </div>
                    <span className="ml-3 text-gray-600">{t('feature1')}</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary/10 flex items-center justify-center mt-0.5">
                      <Check className="h-4 w-4 text-primary" />
                    </div>
                    <span className="ml-3 text-gray-600">{t('feature2')}</span>
                  </li>
                  <li className="flex items-start">
                    <div className="flex-shrink-0 h-6 w-6 rounded-full bg-primary/10 flex items-center justify-center mt-0.5">
                      <Check className="h-4 w-4 text-primary" />
                    </div>
                    <span className="ml-3 text-gray-600">{t('feature3')}</span>
                  </li>
                </ul>
              </div>
              <div className="md:w-1/3">
                <img 
                src={cluster} 
                className="w-full max-w-md shadow-2xl rounded-xl border border-gray-200"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* B2B Section */}
      <section id="b2b" className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="md:flex md:items-center md:space-x-12">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
              {t('b2bTitle')}
              </h2>
              <p className="text-xl text-gray-600 mb-6">
              {t('b2bTagline')}
              </p>
              <p className="text-gray-600 mb-6">
              {t('b2bDescription')}
              </p>
              <div className="space-y-4">
                <div className="flex items-start">
                  <div className="p-2 bg-tertiary/10 rounded-full mt-1">
                    <BarChart2 className="h-5 w-5 text-tertiary" />
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-900">{t('b2bFeature1Title')}</h4>
                    <p className="text-gray-600">{t('b2bFeature1Description')}</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="p-2 bg-tertiary/10 rounded-full mt-1">
                    <LineChart className="h-5 w-5 text-tertiary" />
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-900">{t('b2bFeature2Title')}</h4>
                    <p className="text-gray-600">{t('b2bFeature2Description')}</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="p-2 bg-tertiary/10 rounded-full mt-1">
                    <Zap className="h-5 w-5 text-tertiary" />
                  </div>
                  <div className="ml-4">
                    <h4 className="text-lg font-medium text-gray-900">{t('b2bFeature3Title')}</h4>
                    <p className="text-gray-600">{t('b2bFeature3Description')}</p>
                  </div>
                </div>
              </div>
              
              <div className="mt-8">
                <a 
                  href="#b2b-form"
                  className="px-4 py-2 rounded-md bg-primary text-white hover:bg-primary/90 transition-colors items-center flex w-fit"
                >
                  {t('joinWaitlist')}
                  <ArrowRight className="ml-2 h-4 w-4" />
                </a>
              </div>
            </div>

            <div id="b2b-form" className="md:w-1/2">
              <WaitlistForm />
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      {/* <section className="py-16 bg-gray-50">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold text-gray-900">Frequently Asked Questions</h2>
            <p className="text-gray-600 mt-4">
              Find answers to common questions about Brieft.
            </p>
          </div>
          
          <div className="space-y-6">
            {[
              {
                id: 'faq1',
                question: 'How is Brieft different from other news apps?',
                answer: 'Brieft uniquely groups similar stories into topics, creating a more organized and efficient news reading experience. Our AI technology analyzes and clusters related articles, helping you understand the full story without jumping between different sources.'
              },
              {
                id: 'faq2',
                question: 'How does Brieft\'s B2B platform work?',
                answer: 'Our B2B platform uses a link-based approach to monitor news, rather than expensive content licensing. This allows us to provide advanced media monitoring at a fraction of the cost of traditional services. Our AI enriches the data with sentiment analysis and provides actionable strategic recommendations.'
              },
              {
                id: 'faq3',
                question: 'Is Brieft available in languages other than English?',
                answer: 'Yes! Brieft supports multiple languages with our custom multilingual embedding model. This allows for cross-language article clustering, so you can get comprehensive news coverage regardless of the language.'
              },
              {
                id: 'faq4',
                question: 'How does Brieft ensure data privacy?',
                answer: 'At Brieft, we take your privacy seriously. Your data is stored securely in the European Union, complying with GDPR requirements. We implement appropriate technical and organizational measures to protect your information. For more details, please see our Privacy Policy.'
              },
              {
                id: 'faq5',
                question: 'When will the B2B platform be available?',
                answer: 'We\'re currently developing our B2B platform with select early partners. Join our waitlist to be among the first to access our media intelligence platform when it launches.'
              }
            ].map((faq) => (
              <div key={faq.id} className="border-b border-gray-200 pb-6">
                <button
                  onClick={() => toggleFaq(faq.id)}
                  className="w-full flex justify-between items-center text-left"
                >
                  <span className="text-lg font-medium text-gray-900">{faq.question}</span>
                  <ChevronDown 
                    className={`h-5 w-5 text-gray-500 transform transition-transform ${
                      faqOpen[faq.id] ? 'rotate-180' : ''
                    }`} 
                  />
                </button>
                {faqOpen[faq.id] && (
                  <div className="mt-3 text-gray-600">
                    <p>{faq.answer}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section> */}

      {/* Footer */}
      <Footer />
    </div>
  );
}